import React, {useState, useMemo, useCallback} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    devicesActions,
    driversActions,
    filteredIdActions,
    geofencesActions, groupsActions,
    languageActions, maintenancesActions, positionsActions,
    sessionActions
} from './store';
import { useEffectAsync } from './reactHelper';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from "@material-ui/core/Tooltip";
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import MapIcon from '@material-ui/icons/Map';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import BarChartIcon from '@material-ui/icons/BarChart';
import PeopleIcon from '@material-ui/icons/People';
import StorageIcon from '@material-ui/icons/Storage';
import PersonIcon from '@material-ui/icons/Person';
import NotificationsIcon from '@material-ui/icons/Notifications';
import DescriptionIcon from '@material-ui/icons/Description';
import FolderIcon from '@material-ui/icons/Folder';
import CreateIcon from '@material-ui/icons/Create';
import ReplayIcon from '@material-ui/icons/Replay';
import BuildIcon from '@material-ui/icons/Build';
import Badge from '@material-ui/core/Badge';
import {Avatar, FormControl, Grid, InputLabel, Paper, Select, TextField} from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

// import t from './common/localization';
import {organizationActions} from "./store";
import { Link } from 'react-router-dom';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import CloseIcon from "@material-ui/icons/Close";
import {formatDate} from "./common/formatter";
import MoreIcon from "@material-ui/icons/MoreVert";
import {notificationsActions} from "./store"
import {languages, t} from "./common/LocalizationProvider";
import {Delete, DeleteForeverOutlined, ExpandMore, Language, MoreHoriz} from "@material-ui/icons";
import RemoveDialog from "./RemoveDialog";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DialogActions from "@material-ui/core/DialogActions";

const useStyles = makeStyles(theme => ({
    flexOrgName: {
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    list: {
        width: 250
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    menuButtonMobile: {
        marginLeft: -5,
        marginRight: 0,
    },
    logoSmall: {
        width: 50,
        height: 50,
    },
    backArrowIconContainer: {
        '&:hover': {
            backgroundColor:"transparent",
        },
        color: 'white',
    },
    listTools: {
        paddingLeft: theme.spacing(4)
    },
    backArrowIconContainerMobile: {
        color: 'white',
        position: 'absolute',
        left: theme.spacing(-2),
    },
    cursor: {
        cursor:'pointer'
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            alignItems: 'center',
        },
        paddingLeft: theme.spacing(2)
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    notificationsList: {
        backgroundColor: 'rgb(186 194 199 / 87%)',
        '&:hover': {
            backgroundColor: '#AAAAAA',
        },
    },
    wrapping: {
        whiteSpace: 'normal',
    },
    avatar: {
        cursor:'pointer',
        background: 'white',
        display: 'grid',
        placeItems: 'center',
    },
    linkDecoration: {
        textDecoration: 'none'
    },
    logo: {
        width: '39px',
        height: '39px',
        objectFit: 'contain',
    },
    logoTitle: {
        color: 'black',
        fontWeight: 'bold'
    },
    avatar1: {
        cursor:'pointer',
        background: 'white',
        display: 'grid',
        placeItems: 'center',
        width: '24px', // Adjust the size as needed
        height: '24px', // Adjust the size as needed
        fontSize: '12px', // Adjust the font size to fit the smaller avatar
        color: 'black'
    },
    language: {
        backgroundColor: "white",
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },
    prifileMargin: {
        marginRight: '30px',
        [theme.breakpoints.down('sm')]: { // Apply styles for small screens and below
            marginRight: '0px',
        },
    },
    notificationIconColor: {
    color: 'rgb(51, 51, 102)'
    },
    notificationButtons: {
        marginTop: '1px'
    },
    removeAllButton1: {
        color: '#d32f2f',
        display: 'flex',
        alignItems:'center',
        justifyContent: 'center',
        cursor:'pointer',
        padding: '8px 16px',
        borderRadius: '8px',
        width: '100%',
        '&:hover': {
            backgroundColor: '#FFEBEE'
        }
    },
    moreButton: {
        color: '#1976D2',
        display: 'flex',
        alignItems:'center',
        justifyContent: 'center',
        cursor:'pointer',
        padding: '8px 16px',
        borderRadius: '8px',
        width: '100%',
        '&:hover': {
            backgroundColor: '#E3F2FD',
        },
    },
    icons: {
        marginRight: '8px',
    },
    letters: {
        fontSize: '14px',
        fontWeight: '500'
    },
    removeIcon: {
        color: '#D32F2F',
    }
}));

const NotificationsDropdown = React.memo( ({ openNotsList, onClose, notifications }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const classes = useStyles();
    const [removeDialogShown, setRemoveDialogShown] = useState(false);

    const sortedNotifications = useMemo(() => {
        return [...notifications].sort((a, b) => b.id - a.id);
    },[notifications])

    const language = useSelector(state => state.language.language);

    const handlUnseenChange = async (event, notification) => {
        try {
            if (notification.unseen){
                const response = await fetch('/api/devices/notificationsWS',
                    {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(notification)
                    })
                if (response.ok)
                    dispatch(notificationsActions.updateNotifications(await response.json()))
            }
        }catch (e) {
            console.error('Fetch error:', e.message);
        }
        if (notification.deviceid !== 0)
            history.push('/device/' + notification.deviceid);
        if(notification?.attributes?.title === "confirmEmail")
            history.push('/admin/users');
    }

    const handleRemove = async (notification) => {
        try{
            const response = await fetch('/api/devices/notificationsWS/' + notification.id,
                {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(notification)
                })
            dispatch(notificationsActions.removeNotifications(await response.json()))
        }catch (e) {
            console.error('Fetch error:', e.message);
        }
    }

    const handleGetMore = async () => {
        try{
            const response = await fetch(`/api/devices/notificationsWSMore/${sortedNotifications.length}`);
            const res = await response.json()
            console.log(res);
            dispatch(notificationsActions.updateNotifications(res));
        }catch (e) {
            console.error('Fetch error:', e.message);
        }
    }

    const handleRemoveResult = () => {
        setRemoveDialogShown(false);
    }

    return (
        <div>
            <Menu
            anchorEl={openNotsList}
            open={Boolean(openNotsList)}
            onClose={onClose}
            PaperProps={{ style: {  width: 350 } }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            getContentAnchorEl={null}
        >
                <div style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>
                    <IconButton className={classes.removeAllButton1} onClick={() => setRemoveDialogShown(true)}>
                        <DeleteForeverOutlined className={classes.icons} />
                        <span className={classes.letters}>{t('RemoveAll')}</span>
                    </IconButton>
                </div>

            {sortedNotifications.length > 0 ?(
                sortedNotifications.map((notification) => (
                    <MenuItem
                        key={notification.id}
                        onClick={(event) => handlUnseenChange(event, notification)}
                        className={notification.unseen ? classes.notificationsList : ''}
                    >
                        <ListItemIcon>
                            <NotificationsIcon className={classes.notificationIconColor}/>
                        </ListItemIcon>
                        <ListItemText
                            primary={t(notification.attributes.title)}
                            secondary={
                                <>
                                    {t(notification.attributes.message) && (
                                        <span className={classes.wrapping}
                                              dir={language === 'ar' || language === 'fa' ? 'rtl' : 'ltr'}>{t(notification.attributes.message)
                                            .replace('%u', notification.attributes.senderName)
                                            .replace('%d', notification.attributes.deviceName)
                                              }</span>
                                    )}
                                    <span style={{ display: 'block'}}>{formatDate(notification.created_at)}</span>
                                </>
                            }
                        />
                        <IconButton className={classes.removeButton} onClick={(event) => {
                            event.stopPropagation();
                            handleRemove(notification);
                        }}>
                            <Delete className={classes.removeIcon}/>
                        </IconButton>
                    </MenuItem>
                ))
            ):(
                    <MenuItem
                        key={"1"}
                        style={{ display: 'flex', justifyContent: 'center', padding: '12px 16px', fontSize: '14px', color: '#757575' }}
                    >
                        <span style={{ fontWeight: 500 }} dir={language === 'ar' || language === 'fa' ? 'rtl' : 'ltr'}>
                            {t('noNotificationMessage')}
                        </span>
                    </MenuItem>
            )}
                <IconButton className={classes.moreButton} onClick={handleGetMore}>
                    <ExpandMore className={classes.icons} />
                    <span className={classes.letters}>{t('more')}</span>
                </IconButton>

        </Menu>
            <RemoveDialog open={removeDialogShown} endpoint="notificationsWS" sortedNotifications={sortedNotifications} onResult={handleRemoveResult}/>
        </div>
    );
});

const MainToolbar = () => {

    const dispatch = useDispatch();
    const [drawer, setDrawer] = useState(false);
    const classes = useStyles();
    const history = useHistory();

    const user = useSelector(state => state.session.user);
    const adminEnabled = user.administrator;
    const subadminEnabled = user.subadmin;
    const userId = user.id;
    const location = useLocation();

    const organizationInfo =  useSelector(state => state.organization_information.org ? state.organization_information.org : false);
    const [isMobile, setIsMobile] = useState(false);
    const isSmallScreen = useMediaQuery('(max-width:600px)');

    const notifications = useSelector(state => Object.values(state.notifications.notifications)) //Object.values() convert content from object to array
    const [openNotsList, setOpenNotsList] = useState(null);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(null);
    const [open, setOpen] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);

    const openDrawer = () => {setDrawer(true) }

    const closeDrawer = () => { setDrawer(false) }

    const handleLogout = async () => {
        const response = await fetch('/api/session', { method: 'DELETE' });
        if (response.ok) {
            dispatch(sessionActions.updateUser(null));
            dispatch(devicesActions.refresh([]));
            dispatch(driversActions.refresh([]));
            dispatch(filteredIdActions.updateFilteredId(null));
            dispatch(geofencesActions.refresh([]));
            dispatch(groupsActions.refresh([]));
            dispatch(maintenancesActions.refresh([]));
            dispatch(notificationsActions.refreshNotifications([]));
            dispatch(organizationActions.updateOrg(null));
            dispatch(positionsActions.refresh([]));
            document.cookie = await 'JSESSIONID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
            history.push('/login');
        }
    }

    useEffectAsync(async () => {
        if (!organizationInfo) {
            const response = await fetch('/api/users/getorg');
            if (response.ok) {
                let res = await response.json()
                dispatch(organizationActions.updateOrg(res))
            }
        }
        setIsMobile(isSmallScreen);
    }, [isSmallScreen]);

    function toBlob(base64Data) {
        const binaryData = atob(base64Data);
        const arrayBuffer = new ArrayBuffer(binaryData.length);
        const uint8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < binaryData.length; i++) {
            uint8Array[i] = binaryData.charCodeAt(i);
        }
        const blob = new Blob([arrayBuffer], { type: 'image/png' }); // change the MIME type to match the actual image type
        return blob;
    }


    const handleNotificationClick =useCallback( (event) => {
        //if (notifications.length > 0)
        setOpenNotsList(event.currentTarget);
    },[notifications.length]);

    const handleClose = useCallback( () => {
        setOpenNotsList(null);
    },[]);

    const unseenNotificationCount = useMemo(() => {
        return notifications.reduce((count, not) => {
            return not.unseen ? count + 1 : count;
        },0)
    },[notifications])

    const handleMobileMenuOpen = (event) => {
        setMobileMenuOpen(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMenuOpen(null);
    };

    const languageSelected = useSelector(state => state.language.language);

    const settinglanguage = (e) => {
        dispatch(languageActions.updateLanguage(e.target.value))
    }


    return (
        <>
            <AppBar position="static" className={classes.appBar}>
                <Toolbar className={!(location.pathname === '/') && isMobile ? classes.listTools : ''}>
                    {!(location.pathname === '/') &&
                    <IconButton
                        onClick={() => history.push('/')}
                        className={!isMobile ? classes.backArrowIconContainer : classes.backArrowIconContainerMobile}
                        disableRipple>
                        <ArrowBackIcon />
                    </IconButton>}

                    <Tooltip title={t('menu')} >
                    <IconButton
                        className={!isMobile ? classes.menuButton : classes.menuButtonMobile}
                        color="inherit"
                        onClick={openDrawer}>
                        <MenuIcon />
                    </IconButton>
                    </Tooltip>

                    {!isMobile && <Typography variant="h6" color="inherit" >
                        Tracthing
                    </Typography>}

                    {organizationInfo && organizationInfo.length > 0 &&
                    <Typography variant="h6" color="inherit"  className={classes.flexOrgName}>
                        {organizationInfo[0].organization.toUpperCase()}
                    </Typography>
                    }

                    {adminEnabled &&
                    <Typography variant="h6" color="inherit"  className={classes.flexOrgName}>
                        {adminEnabled && (t('superAdmin'))}
                    </Typography>
                    }

                    <Tooltip title={t('profile')} className={classes.prifileMargin} >
                        <Link to={"/user/" + userId} className={classes.linkDecoration}>
                            <Avatar className={classes.avatar}>
                                <Grid item xs={12}>
                                    <label htmlFor="logo-upload">
                                        {adminEnabled &&
                                        <img src='/tracthing.png' alt="SuperAdmin Logo" className={classes.logo}/>
                                        }
                                        {organizationInfo && organizationInfo.length > 0 &&
                                        <>
                                            {
                                                organizationInfo[0].logo ? (
                                                    <img src={URL.createObjectURL(toBlob(organizationInfo[0].logo))} alt="Organization Logo" className={classes.logo}/>
                                                ) : (
                                                    <span className={classes.logoTitle}>{organizationInfo[0].organization.charAt(0).toUpperCase()}</span>
                                                )
                                            }
                                        </>}
                                    </label>
                                </Grid>
                            </Avatar>
                        </Link>
                    </Tooltip>

                    <div className={classes.sectionDesktop} >
                        <Tooltip title={t('loginLanguage')} >
                            <Language
                                onClick={() => setOpen(prevState => !prevState)}
                                size="small"
                                style={{ cursor: 'pointer', marginRight: "20px"  }}
                            />
                        </Tooltip>
                        {open &&
                        <Select
                            onChange={settinglanguage}
                            open={open}
                            onClose={() => setOpen(false)}
                            style={{ display: open ? 'block' : 'none' }} // Hide the Select component when not open
                        >
                            {languages.map((it) => (
                                <MenuItem key={it.code} value={it.code}>{it.name}</MenuItem>
                            ))}
                        </Select>}

                        <Tooltip title={t('sharedNotifications')} >
                            <IconButton aria-label="show all new notifications" color="inherit" onClick={handleNotificationClick}>
                                {/*<Badge badgeContent={memorizedNotifications.length} color="secondary" overlap={"rectangular"}>*/}
                                    <Badge badgeContent={unseenNotificationCount} color="secondary" overlap={"rectangular"}>
                                    <NotificationsIcon />
                                </Badge>
                            </IconButton>
                        </Tooltip>

                        <Tooltip title={t("exit")}>
                            <Button color="inherit" onClick={() => setOpenDialog(true)} > <ExitToAppIcon /></Button>
                        </Tooltip>
                    </div>

                    <div className={classes.sectionMobile}>
                        <IconButton
                            aria-label="show more"
                            aria-controls={"primary-search-account-menu-mobile"}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >
                            <MoreIcon />
                        </IconButton>
                    </div>
                    <NotificationsDropdown openNotsList={openNotsList} onClose={handleClose} notifications={notifications} />

                    <div>
                        <Menu
                            anchorEl={mobileMenuOpen}
                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                            id={'primary-search-account-menu-mobile'}
                            keepMounted
                            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                            open={Boolean(mobileMenuOpen)}
                            onClose={handleMobileMenuClose}
                        >
                            <MenuItem>
                                <Language style={{ marginLeft: "15px" }} ></Language>
                                <FormControl variant="filled">
                                    <Select
                                        value={languageSelected || 'en'}
                                        onChange={settinglanguage}
                                        className={classes.language}
                                    >
                                        {languages.map((it) => (
                                            <MenuItem key={it.code} value={it.code}>{it.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </MenuItem>
                            <MenuItem onClick={handleNotificationClick} >
                                <IconButton aria-label="show some new notifications" color="inherit">
                                    <Badge badgeContent={unseenNotificationCount} color="secondary" overlap={"rectangular"}>
                                        <NotificationsIcon />
                                    </Badge>
                                </IconButton>
                                <p>{t("sharedNotifications")}</p>
                            </MenuItem>
                            <MenuItem onClick={() => setOpenDialog(true)}>
                                <Tooltip title={t("exit")}>
                                    <Button color="inherit" style={{ marginLeft: "-4px" }} > <ExitToAppIcon /></Button>
                                </Tooltip>
                                <p>{t("exit")}</p>
                            </MenuItem>
                        </Menu>
                    </div>

                </Toolbar>
            </AppBar>

            <Drawer open={drawer} onClose={closeDrawer}>
                <div
                    tabIndex={0}
                    className={classes.list}
                    role="button"
                    onClick={closeDrawer}
                    onKeyDown={closeDrawer}>
                    <List>
                        <ListItem button onClick={() => history.push('/')}>
                            <ListItemIcon>
                                <MapIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('mapTitle')} />
                        </ListItem>
                        <ListItem button onClick={() => history.push('/replay')}>
                            <ListItemIcon>
                                <ReplayIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('reportReplay')} />
                        </ListItem>
                        <ListItem button onClick={() => history.push('/reports/route')}>
                            <ListItemIcon>
                                <DescriptionIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('reportTitle')} />
                        </ListItem>
                    </List>
                    <Divider />
                    <List
                        subheader={
                            <ListSubheader>
                                {t('settingsTitle')}
                            </ListSubheader>
                        }
                    >
                        <ListItem button disabled={!userId} onClick={() => history.push(`/user/${userId}`)}>
                            <ListItemIcon>
                                <PersonIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('settingsUser')} />
                        </ListItem>
                        <ListItem button onClick={() => history.push('/geofences')}>
                            <ListItemIcon>
                                <CreateIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('sharedGeofences')} />
                        </ListItem>
                        <ListItem button onClick={() => history.push('/settings/notifications')}>
                            <ListItemIcon>
                                <NotificationsIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('sharedNotifications')} />
                        </ListItem>
                        <ListItem button onClick={() => history.push('/settings/groups')}>
                            <ListItemIcon>
                                <FolderIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('settingsGroups')} />
                        </ListItem>
                        <ListItem button onClick={() => history.push('/settings/drivers')}>
                            <ListItemIcon>
                                <PersonIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('sharedDrivers')} />
                        </ListItem>
                        <ListItem button onClick={() => history.push('/settings/attributes')}>
                            <ListItemIcon>
                                <StorageIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('sharedComputedAttributes')} />
                        </ListItem>
                        <ListItem button onClick={() => history.push('/settings/maintenances')}>
                            <ListItemIcon>
                                <BuildIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('sharedMaintenance')} />
                        </ListItem>
                    </List>
                    {subadminEnabled && (
                        <>
                            <Divider />
                            <List subheader={
                                <ListSubheader>
                                    {t('userAdmin')}
                                </ListSubheader>}
                            >
                                {adminEnabled && (
                                    <ListItem button onClick={() => history.push('/admin/server')}>
                                        <ListItemIcon>
                                            <StorageIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={t('settingsServer')} />
                                    </ListItem>)}
                                <ListItem button onClick={() => history.push('/admin/users')}>
                                    <ListItemIcon>
                                        <PeopleIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={t('settingsUsers')} />
                                </ListItem>
                                <ListItem button onClick={() => history.push('/admin/statistics')}>
                                    <ListItemIcon>
                                        <BarChartIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={t('statisticsTitle')} />
                                </ListItem>
                            </List>
                        </>
                    )}
                </div>
            </Drawer>

            <Dialog
                open={openDialog}
                onClose={() => { setOpenDialog(false) }}>
                <DialogContent>
                    <DialogContentText >{t('exitMessage')}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={handleLogout}  style={{color: 'red'}}>{t('exit')}</Button>
                    <Button autoFocus onClick={() => setOpenDialog(false)} >{t('stay')}</Button>
                </DialogActions>
            </Dialog>

        </>
    );
}
export default MainToolbar;
