import React, {useEffect, useState} from 'react';
import {IconButton, isWidthUp, ListItem, makeStyles, withWidth} from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import ContainerDimensions from 'react-container-dimensions';
import DevicesList from './DevicesList';
import MainToolbar from './MainToolbar';
import Map from './map/Map';
import SelectedDeviceMap from './map/SelectedDeviceMap';
import AccuracyMap from './map/AccuracyMap';
import GeofenceMap from './map/GeofenceMap';
import CurrentPositionsMap from './map/CurrentPositionsMap';
import CurrentLocationMap from './map/CurrentLocationMap';
import List from "@material-ui/core/List";
import {KeyboardArrowDown, DeleteForeverOutlined} from "@material-ui/icons";
import DevicesIcon from '@material-ui/icons/Devices';
import DescriptionIcon from '@material-ui/icons/Description';
import PersonIcon from "@material-ui/icons/Person";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import {useHistory} from "react-router-dom";
import { t } from "./common/LocalizationProvider";
import {useSelector} from "react-redux";

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flexGrow: 1,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
    }
  },
  drawerPaper: {
    background:'#EAEAEA',
    position: 'relative',
    transition: 'all 0.4s ease',
    [theme.breakpoints.up('sm')]: {
      width: 350,
    },
    [theme.breakpoints.down('xs')]: {
      height: 350,
    },
    overflow: 'hidden',
  },
    drawerPaperHide: {
        background:'#EAEAEA',
        position: 'relative',
        transition: 'all 0.4s ease',
        [theme.breakpoints.down('xs')]: {
            height: 0,
        },
        overflow: 'hidden',
    },
  mapContainer: {
    flexGrow: 1,
  },
  dragger: {
    width: '5px',
    cursor: 'ew-resize',
    padding: '4px 0 0',
    borderTop: '1px solid #ddd',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: '100',
    backgroundColor: '#f4f7f9',
  },
  list: {
    display: 'flex', // Make the list items horizontal
    justifyContent: 'space-between',
    padding: theme.spacing(0.5), // Optional: Add padding
    // overflow: 'auto',  use this when you have a container that has a limited size (e.g., a fixed height or width) but the content inside may vary in size, and you want the user to be able to scroll through the extra content.
  },
  arrowDown: {
    height: 20,
    background:'#EAEAEA',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  iconsList: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: 'rgb(51, 51, 102)',
  },
}));

const MainPage = ({ width }) => {
  const classes = useStyles();
  const [state, setState] = useState({isResizing: false, newWidth: {}});
  const [deviceList, setDeviceList] = useState(false);

  const history = useHistory();
  const user = useSelector(state => state.session.user);
  const userId = user.id;


  const handleMousedown = (e) => {
    setState(prevState => ({ ...prevState, isResizing: true }));
  };

  const handleMousemove = (e) => {
    // we don't want to do anything if we aren't resizing.
    if (!state.isResizing) {
      return;
    }

    const offsetRight = e.clientX;
    const minWidth = 100;
    const maxWidth = 600;
    if (offsetRight > minWidth && offsetRight < maxWidth) {
      setState(prevState => ({ ...prevState, newWidth: { width: offsetRight } }));
    }
  };

  const handleMouseup = (e) => {
    setState(prevState => ({...prevState, isResizing: false}));
  };

  useEffect(() => {
    document.addEventListener('mousemove', handleMousemove);
    document.addEventListener('mouseup', handleMouseup);

    return () => {
      document.removeEventListener('mousemove', handleMousemove);
      document.removeEventListener('mouseup', handleMouseup);
    }
  },[state.isResizing])

  return (
      <div className={classes.root}>
        <MainToolbar />
        <div className={classes.content}>
          <Drawer
              anchor={isWidthUp('sm', width) ? 'right' : 'bottom'}
              variant='permanent'
              classes={{ paper: deviceList ? classes.drawerPaper : classes.drawerPaperHide }}
              PaperProps={{ style: state.newWidth }}
          >
            <div
                id="dragger"
                onMouseDown={(event) => handleMousedown(event)}
                className={classes.dragger}
            />
            <DevicesList />
          </Drawer>
            {!isWidthUp('sm', width) && deviceList &&
            <div onClick={event => setDeviceList(false)} className={classes.arrowDown}>
                <IconButton>
                    <KeyboardArrowDown/>
                </IconButton>
            </div>}
            {!deviceList && !isWidthUp('sm', width) &&
            <List className={classes.list}>
                <ListItem onClick={event => setDeviceList(true)}>
                    <ListItemIcon className={classes.iconsList}>
                      <DevicesIcon />
                      <span >{t('deviceTitle')}</span>
                    </ListItemIcon>
                </ListItem>
                <ListItem button onClick={() => history.push('/reports/route')}>
                  <ListItemIcon className={classes.iconsList}>
                      <DescriptionIcon/>
                      <span >{t('reportTitle')}</span>
                    </ListItemIcon>
                </ListItem>
                <ListItem onClick={() => history.push(`/user/${userId}`)}>
                  <ListItemIcon className={classes.iconsList}>
                      <PersonIcon/>
                      <span >{t('settingsUser')}</span>
                    </ListItemIcon>
                </ListItem>
                <ListItem>
                  <ListItemIcon className={classes.iconsList}>
                      <DeleteForeverOutlined/>
                      <span >change</span>
                    </ListItemIcon>
                </ListItem>
            </List>}
          <div className={classes.mapContainer}>
            <ContainerDimensions>
              <Map>
                <CurrentLocationMap />
                <GeofenceMap />
                <AccuracyMap />
                <CurrentPositionsMap />
                <SelectedDeviceMap />
              </Map>
            </ContainerDimensions>
          </div>
        </div>
      </div>
  );
}

export default withWidth()(MainPage);
