import { t } from './common/LocalizationProvider'
import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import {makeStyles, Snackbar, TextField} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import {notificationsActions} from "./store";
import {useDispatch} from "react-redux";

const useStyles = makeStyles((theme) => ({
    loading: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'fixed',     // Fixed positioning to stay on top
        top: '90%',            // Center vertically
        left: '50%',           // Center horizontally
        transform: 'translate(-50%, -50%)', // Center the element
        zIndex: theme.zIndex.tooltip + 1,   // Ensure it appears above other elements
        backgroundColor: 'rgba(255, 255, 255, 0.8)', // Optional: Add background color for better visibility
        padding: theme.spacing(2), // Optional: Add some padding
        borderRadius: theme.shape.borderRadius, // Optional: Rounded corners
    },
}));

const RemoveDialog = ({ open, endpoint, itemId, onResult, sortedNotifications }) => {
    const storedValue = localStorage.getItem('tokenThingwings');
    const classes = useStyles();
    const dispatch = useDispatch();

    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [msg, setmsg] = useState('');
    const [loading, setLoading] = useState(false);

    const handleTogglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    const handleRemove = async () => {
        setLoading(true)
        const response = await fetch(`/api/${endpoint}/${itemId}`, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json',
            'X-Auth-Token': 'Bearer ' + storedValue,
            'password': password
        }});
    if (response)
        setLoading(false)

    if (response.ok) {
      onResult(true);
    }
    else if (response.status === 400){
        const errorText = await response.text();
        let spliteText = errorText.split(' ')[0];
        setmsg(t(spliteText))
        setOpenSnackbar(true)
    }
    else {
        setmsg(t('UnknownProblem'))
        setOpenSnackbar(true)
    }
  };

    const handleRemoveLastNots = async () => {
        try{
            const response = await fetch('/api/devices/deleteLastWS',
                {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(sortedNotifications)
                })
            if (response.ok)
                dispatch(notificationsActions.refreshNotifications(await response.json()))
            else if (response.status === 400){
                const errorText = await response.text();
                let spliteText = errorText.split(' ')[0];
                setmsg(t(spliteText))
                setOpenSnackbar(true)
            }
            else {
                setmsg(t('UnknownProblem'))
                setOpenSnackbar(true)
            }
        }catch (e) {
            console.error('Fetch error:', e.message);
        }
    }

    const handleRemoveAllNots = async () => {
        try{
            const response = await fetch('/api/devices/deleteAllWS',
                {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                    },})
            if (response.ok)
                dispatch(notificationsActions.clearNotifications());
            else if (response.status === 400){
                const errorText = await response.text();
                let spliteText = errorText.split(' ')[0];
                setmsg(t(spliteText))
                setOpenSnackbar(true)
            }
            else {
                setmsg(t('UnknownProblem'))
                setOpenSnackbar(true)
            }
        }catch (e) {
            console.error('Fetch error:', e.message);
        }
    }

    function showedMessage() {
      if (endpoint === "devices")
          return t('sharedRemoveBecareful');
      else if (endpoint === "notificationsWS")
          return t('notsWSRemoveAllBecareful');
      else
        return t('sharedRemoveConfirm')
    }

    return (
        <>
    <Dialog
      open={open}
      onClose={() => { onResult(false) }}>
      <DialogContent>
            <DialogContentText >{showedMessage()}</DialogContentText>
      </DialogContent>
        {endpoint === "devices" &&
        <TextField
            margin="normal"
            value={password || ''}
            onChange={(e) => setPassword(e.target.value)}
            label={t("userPassword")}
            variant="filled"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}/>
        }
      <DialogActions>
          {endpoint === "notificationsWS" &&
              <Button color="primary" onClick={handleRemoveAllNots} style={{color: 'red'}}>{t('RemoveAll')}</Button>}
          <Button color="primary" onClick={endpoint === "notificationsWS" ? handleRemoveLastNots : handleRemove}
                  style={{color: 'red'}}> {endpoint === "notificationsWS"  ? t('RemoveLast') : t('sharedRemove')} </Button>
        <Button autoFocus onClick={() => onResult(false)}>{t('sharedCancel')}</Button>
      </DialogActions>
    </Dialog>

    <Snackbar open={openSnackbar} autoHideDuration={12000} onClose={handleCloseSnackbar} >
       <Alert onClose={handleCloseSnackbar} variant="filled" severity="error">
          {msg}
       </Alert>
    </Snackbar>

    {loading && <div className={classes.loading}>
        <CircularProgress color="secondary" />
    </div>}
   </>
  );
};

export default RemoveDialog;
