import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {Tabs, Tab, Box, TextField, Button, FormControl, InputLabel, Select, MenuItem, Snackbar,} from '@material-ui/core';
import MainToolbar from './MainToolbar';
import { t } from "./common/LocalizationProvider";
import {useParams} from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import {useSelector} from "react-redux";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CircularProgress from "@material-ui/core/CircularProgress";
import useMediaQuery from "@material-ui/core/useMediaQuery";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        height: 300,
    },
    rootResponsive: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        flexDirection: 'column', // Adjusted for responsiveness
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row', // Horizontal layout on medium screens and above
        },
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.primary,
    },
    tabs: {
        borderRight: `2px solid ${theme.palette.divider}`,
    },
    title: {
        marginLeft: 50,
    },
    send: {
        marginLeft: 150,
        textTransform: "none",
    },
    sendSecond: {
        marginTop: theme.spacing(2), // Add space between the buttons
        width: '100%', // Make the buttons take full width
        textTransform: 'none',
    },
    length: {
        marginLeft: 20,

    } , formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    loading: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (<Box p={3}>{children}</Box>)}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const CommandsPage = () => {
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [type, setType] = useState(t("DeviceOverspeed"));
    const [val, setVal] = useState(0);
    const [val2, setVal2] = useState(0);
    const [choice, setChoice] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [message, setMessage] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);

    //const positions = useSelector(state => Object.values(state.positions.items));
    const { id } = useParams();
    let deviceId = parseInt(id);
    const isSmallScreen = useMediaQuery('(max-width:600px)');


    const handleChange = (event, newValue) => {
        setValue(newValue);
        if(newValue === 0){
            setType(t("DeviceOverspeed"))
        }
        if(newValue === 1){
            setType(t("displacementalarm"))
        }
        if(newValue === 2){
            setType(t("stopengine"))
        }
        if(newValue === 3){
            setType(t("eventDeviceFuelDrop"))
        }
        if(newValue === 4){
            setType(t("dimensions"))
        }
        if(newValue === 5){
            setType(t("customnotifications"))
        }
    };

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenDialog(false);
    };

    const handleOpenSnackbar = () => {
        setOpenSnackbar(true);
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    const handleSend = async (e) => {
        e.preventDefault();

        let description = 0;
        var bodyContent ={}
        if(value === 0){
            description = JSON.stringify({maxSpeed:parseFloat(val)});
            bodyContent={ type: "dimensions", deviceId, description}
        }
        if(value === 1){
            description = JSON.stringify({maxDisplacement:parseFloat(val)});
            bodyContent={ type: "dimensions", deviceId, description}
        }
        if(value === 2){
            // description = JSON.stringify({Keeyword:choice, msgID:Math.floor(Math.random() * 100000) });
            description = JSON.stringify({Keeyword:choice});
            bodyContent = {type: "dimensions", deviceId, description}
        }
        if(value === 3){
            description = JSON.stringify({FuelDrop:parseFloat(val)});
            bodyContent={ type: "dimensions", deviceId, description}
        }
        if(value === 4){
            description = JSON.stringify({radius:parseFloat(val), Lenght:parseFloat(val2)});
            bodyContent={ type: "dimensions", deviceId, description}
        }
        if (value === 5) {
            description = JSON.stringify({Keeyword:choice });
            setType("dimensions") ;
            bodyContent={ type: "dimensions", deviceId, description}
        }

        setLoading(true)
        const headers= { 'Content-Type': 'application/json' }
        if (value === 2)
            headers['Password'] = password
        const response = await fetch( 'api/cmd/send', {
            method:  'POST',
            headers: headers,
            body: JSON.stringify(bodyContent),
        });
        if (response.status === 200)
            setMessage("cmdSendSuccess")
        else if (response.status === 400){
            const errorText = await response.text();
            let spliteText = errorText.split(' ')[0];
            setMessage(spliteText)
        }
        else {
            setMessage('UnknownProblem')
        }
        handleOpenSnackbar()
        handleCloseDialog()
        setLoading(false)
    };

    function snackbarDuration(){
        if (message === "cmdSendSuccess") return 6000;
        else return 12000;
    }

    const handleTogglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    return (
        <>
            <MainToolbar/>
            <br/>
            <br/>
            <br/>
            <h2 className={classes.title}>{t("commands")}</h2>
            <div className={!isSmallScreen ? classes.root : classes.rootResponsive}>
                <Tabs
                    orientation="vertical"
                    value={value}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    className={classes.tabs}
                >
                    <Tab label={t("DeviceOverspeed")} {...a11yProps(0)} />
                    <Tab label={t("displacementalarm")} {...a11yProps(1)} />
                    <Tab label={t("stopengine")} {...a11yProps(2)} />
                    <Tab label={t("eventDeviceFuelDrop")} {...a11yProps(3)} />
                    <Tab label={t("dimensions")} {...a11yProps(4)} />
                    <Tab label={t("customnotifications")} {...a11yProps(5)} />
                </Tabs>

                <div className="mb-3">
                    <TabPanel value={value} index={0}>
                        <h3>{t(type)}</h3>
                        <TextField
                            margin="normal"
                            value={val || ''}
                            onChange={(e) => setVal(e.target.value)}
                            label={t("valuein") +t("kms")}
                            variant="filled" />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <h3>{t(type)}</h3>
                        <TextField
                            margin="normal"
                            value={val || ''}
                            onChange={(e) => setVal(e.target.value)}
                            label={t("valuein") +t("km")}
                            variant="filled" />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <h3>{t(type)}</h3>
                        <FormControl variant="outlined" fullWidth className={classes.formControl}>
                            <InputLabel id="demo-simple-select-outlined-label">{t('commandTitle')}</InputLabel>
                            <Select
                                fullWidth
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={choice}
                                onChange={(e) => setChoice(e.target.value)}
                                label={t("StopEngine request")}>
                                {/*<MenuItem value="">*/}
                                {/*    <em>{t("none")}</em>*/}
                                {/*</MenuItem>*/}
                                <MenuItem value={"motor_off"}>{t("stop")}</MenuItem>
                                <MenuItem value={"motor_on"}>{t("start")}</MenuItem>
                            </Select>
                        </FormControl>
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <h3>{t(type)}</h3>
                        <TextField
                            margin="normal"
                            value={val || ''}
                            onChange={(e) => setVal(e.target.value)}
                            label={t("valuein") +t("liter")}
                            variant="filled" />
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                        <h3>{t(type)}</h3>
                        <TextField
                            margin="normal"
                            value={val || ''}
                            onChange={(e) => setVal(e.target.value)}
                            label={t("height") +t("sharedMeters")}
                            variant="filled"
                            className={classes.length}/>

                        <TextField
                            margin="normal"
                            value={val2 || ''}
                            onChange={(e) => setVal2(e.target.value)}
                            label={t("width") +t("sharedMeters")}
                            variant="filled"
                            className={classes.length} />

                    </TabPanel>
                    <TabPanel value={value} index={5}>
                        <h3>{t(type)}</h3>
                        <FormControl variant="outlined" fullWidth className={classes.formControl}>
                            <InputLabel id="demo-simple-select-outlined-label">{t('sharedNotifications')}</InputLabel>
                            <Select
                                fullWidth
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={choice}
                                onChange={(e) => setChoice(e.target.value)}
                                label={t("customnotifications")}
                            >
                                <MenuItem value="">
                                    <em>{t("none")}</em>
                                </MenuItem>
                                <MenuItem value={"location"}>{t("location")}</MenuItem>
                                <MenuItem value={"speed"}>{t("positionSpeed")}</MenuItem>
                            </Select>
                        </FormControl>
                    </TabPanel>

                    <Button className={classes.send} type='button' color='primary' variant='contained'  onClick={(e)=>{handleOpenDialog()}}>
                        {t("commandSend")}
                    </Button>
                </div>
            </div>

            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{t("confirmation")}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t("send message request")}
                    </DialogContentText>
                    {value === 2 && <>
                        <TextField
                            margin="normal"
                            value={password || ''}
                            onChange={(e) => setPassword(e.target.value)}
                            label={t("userPassword")}
                            variant="filled"
                            type={showPassword ? 'text' : 'password'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                                            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}/>
                        <h1/>
                    </>}
                    <Button className={!isSmallScreen ? classes.send : classes.sendSecond} type='button'   onClick={handleCloseDialog}>
                        {t("sharedCancel")}
                    </Button>
                    <Button  className={!isSmallScreen ? null : classes.sendSecond} type='button' color='primary' variant='contained'  onClick={(e)=>{handleSend(e)}}>
                        {t("send")}
                    </Button>
                    {loading && <div className={classes.loading}>
                        <CircularProgress color="secondary" />
                    </div>}
                </DialogContent>
            </Dialog>

            <Snackbar open={openSnackbar} autoHideDuration={snackbarDuration()} onClose={handleCloseSnackbar } >
                <Alert onClose={handleCloseSnackbar } variant="filled" severity={message === "cmdSendSuccess" ? "success" : "error"}>
                    {t(message)}
                </Alert>
            </Snackbar>


        </>
    );
}

export default CommandsPage;
