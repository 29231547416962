import React, {useState, useEffect, useRef, useMemo} from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Container, makeStyles, Paper, Slider, Tooltip, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MainToolbar from '../MainToolbar';
import Map from '../map/Map';
import { t } from '../common/LocalizationProvider';
import ReplayPathMap from '../map/ReplayPathMap';
import PositionsMap from '../map/PositionsMap';
import {formatPosition, formatSpeed, formatDistance} from '../common/formatter';
import ReportFilter from './ReportFilter';
import {useParams} from "react-router-dom";
import IconButton from '@material-ui/core/IconButton';
import PauseIcon from '@material-ui/icons/Pause';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import FastRewindIcon from '@material-ui/icons/FastRewind';
import FastForwardIcon from '@material-ui/icons/FastForward';
import { formatDate } from '../common/formatter';
import {map} from "../map/Map";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {filteredIdActions} from '../store'
import {useDispatch} from "react-redux";
import mapboxgl from 'mapbox-gl';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import StartsStops from "../map/StartsStops";

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    controlPanel: {
        position: 'absolute',
        bottom: theme.spacing(2),
        left: '50%',
        transform: 'translateX(-50%)',
    },
    controlContent: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
        backgroundColor: 'rgba(255, 255, 255, 0.5)', // 50% transparency white
        boxShadow: 'none', // Remove any box shadow if needed
    },
    configForm: {
        display: 'flex',
        flexDirection: 'column',
    },
    timeDisplay: {
        marginLeft: theme.spacing(2),
        fontStyle: 'italic',
        color: 'gray',
    },
    accordion: {
        marginBottom: theme.spacing(2),
        backgroundColor: 'rgba(255, 255, 255, 0.5)', // 50% transparency white
        boxShadow: 'none', // Remove any box shadow if needed
    },
    accordionSummary: {
        backgroundColor: 'rgba(255, 255, 255, 0.5)', // 50% transparency white
    },
}));

const TimeLabel = ({ children, open, value }) => {
    return (
        <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
            {children}
        </Tooltip>
    );
};

const ReplayPage = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const intervalId = useRef()

    const [expanded, setExpanded] = useState(true);
    const [positions, setPositions] = useState([]);
    const [index, setIndex] = useState(0);
    const [playing, setPlaying] = useState(false); // Add playing state
    const { id } = useParams();
    const [speedType, setSpeedType] = useState('Medium');
    const [open, setOpen] = useState(false);
    const [distanceAccu, setDistanceAccu] = useState(0);
    const [subPositions, setSubPositions] = useState([]);
    const [stopsPositions, setStopsPositions] = useState([]);
    const [loading, setLoding] = useState(false);

    dispatch(filteredIdActions.updateFilteredId(id));

    const handleSubmit = async (deviceId, from, to, _, headers) => {
        const query = new URLSearchParams({ deviceId, from, to });
        setLoding(true)
        const response = await fetch(`/api/positions?${query.toString()}`, { headers });
        if (response.ok) {
            setIndex(0);
            const res = await response.json();
            setPositions(res);
            setExpanded(false);
            if (res.length === 0)
            setOpen(true);
        }
        setLoding(false)
    };

    const handleStopsPositions = async (deviceId, from, to, _, headers) => {
        const mail = false;
        const query = new URLSearchParams({ deviceId, from, to, mail });
        const response = await fetch(`/api/reports/stops?${query.toString()}`, { headers });
        if (response.ok) {
            const contentType = response.headers.get('content-type');
            if (contentType) {
                if (contentType === 'application/json') {
                    let items = await response.json()
                    items = items.slice(1)
                    setStopsPositions(items)
                }
                else
                    window.location.assign(window.URL.createObjectURL(await response.blob()))
            }
        }
    };

    const togglePlay = () => {
        setPlaying(!playing);
    };

    const goToPreviousPosition = () => {
        setIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
        const position = positions[index-1];
        const bounds = new mapboxgl.LngLatBounds();
        bounds.extend([position.longitude, position.latitude]);
        map.fitBounds(bounds, {
            zoom: [15],
            padding: { top: 5, bottom: 295, left: 25, right: 25 }
        });
        // map.flyTo({
        //     center: [position.longitude, position.latitude],
        //     zoom: [15],
        //     padding: { top: 5, bottom: 295, left: 25, right: 25 },
        // });
    };

    const goToNextPosition = () => {
        setIndex((prevIndex) => (prevIndex < positions.length - 1 ? prevIndex + 1 : prevIndex));
        const position = positions[index+1];
        const bounds = new mapboxgl.LngLatBounds();
        bounds.extend([position.longitude, position.latitude]);
        map.fitBounds(bounds, {
            zoom: [15],
            padding: { top: 5, bottom: 295, left: 25, right: 25 }
        });
        // map.flyTo({
        //     center: [position.longitude, position.latitude],
        //     zoom: [15],
        //     padding: { top: 5, bottom: 295, left: 25, right: 25 },
        // });
    };

    const speedValues = {
        Slow: 500,
        Medium: 300,
        Fast: 20
    };

    const handleToggleSpeed = () => {
        // Define the order of speed types
        const speedTypes = ['Slow', 'Medium', 'Fast'];

        // Find the current index of the speed type
        const currentIndex = speedTypes.indexOf(speedType);

        // Calculate the next index (looping back to the start if at the end)
        const nextIndex = (currentIndex + 1) % speedTypes.length;

        // Update the speed type
        setSpeedType(speedTypes[nextIndex]);
    };

    useEffect(() => {
        if (positions)
            setSubPositions(positions.slice(0, index+1))
            setDistanceAccu(index === 0 ? 0 : positions[index].attributes.totalDistance - positions[0].attributes.totalDistance);
    }, [index, positions]);

    // const memoizedSubPositions = useMemo(() => {
    //     return positions.slice(0, index + 1);
    // }, [index]);
    //
    // const memorizeDistanceAccumulator = useMemo(() => {
    //     return index === 0 ? 0 : positions[index].attributes.totalDistance - positions[0].attributes.totalDistance;
    // }, [positions, index])
    //
    // useEffect(() => {
    //     setSubPositions(memoizedSubPositions);
    // }, [memoizedSubPositions]);
    //
    // useEffect(() => {
    //     setDistanceAccu(memorizeDistanceAccumulator)
    // }, [memorizeDistanceAccumulator]);

    useEffect(() => {
        if (playing && index < positions.length - 1) {
            if (intervalId.current)
                clearInterval(intervalId.current)
            intervalId.current = setInterval(() => {setIndex((prevIndex) => (prevIndex < positions.length - 1 ? prevIndex + 1 : prevIndex));}, speedValues[speedType]);
                const position = positions[index];
                // map.flyTo({
                //     center: [position.longitude, position.latitude],
                //     zoom: [15],
                //     padding: { top: 5, bottom: 295, left: 25, right: 25 },
                // });
            const bounds = new mapboxgl.LngLatBounds();
            bounds.extend([position.longitude, position.latitude]);
            map.fitBounds(bounds, {
                zoom: [15],
                speed: 1.5,
                padding: { top: 5, bottom: 295, left: 25, right: 25 }
            });
        }
        if (index === positions.length - 1) {
            setPlaying(false); // Stop playing when the last position is reached
        }

        return () => {
            clearInterval(intervalId.current);
        };
    }, [playing, index, positions, speedType]);


    // const decodePolyline = (encoded) => {
    //     let len = encoded.length;
    //     let index = 0;
    //     let lat = 0;
    //     let lng = 0;
    //     const coordinates = [];
    //
    //     while (index < len) {
    //         let byte, shift = 0, result = 0;
    //
    //         // Decode latitude
    //         do {
    //             byte = encoded.charCodeAt(index++) - 63;
    //             result |= (byte & 0x1f) << shift;
    //             shift += 5;
    //         } while (byte >= 0x20);
    //         lat += (result & 1) ? ~(result >> 1) : (result >> 1);
    //
    //         shift = 0;
    //         result = 0;
    //
    //         // Decode longitude
    //         do {
    //             byte = encoded.charCodeAt(index++) - 63;
    //             result |= (byte & 0x1f) << shift;
    //             shift += 5;
    //         } while (byte >= 0x20);
    //         lng += (result & 1) ? ~(result >> 1) : (result >> 1);
    //
    //         coordinates.push([lat / 1e5, lng / 1e5]);
    //     }
    //
    //     return coordinates;
    // };
    //
    // const memorizeIndex = useMemo(() => {
    //     const distanceDef = index === 0 ? 0 : positions[index].attributes.totalDistance - positions[index-1].attributes.totalDistance;
    //     return distanceDef > 500 ? index : 0;
    // }, [positions, index])
    //
    // useEffect(() => {
    //     // const latA = 35.9115367, lonA = 5.2997783;  // Algiers (Start point)
    //     // const latB = 35.9340175, lonB = 5.3687635;  // Oran (End point)
    //
    //     if (memorizeIndex !== 0){
    //         const latA = positions[memorizeIndex-1].latitude, lonA = positions[memorizeIndex-1].longitude;  // Algiers (Start point)
    //         const latB = positions[memorizeIndex].latitude, lonB = positions[memorizeIndex].longitude;  // Oran (End point)
    //         console.log("useEffect with playing")
    //         fetch(`https://router.project-osrm.org/route/v1/cycling/${lonA},${latA};${lonB},${latB}?overview=full&steps=true`)
    //             .then(response => response.json())
    //             .then(data => {
    //                 //console.log(data.routes[0].geometry)
    //                 const decodedCoordinates = decodePolyline(data.routes[0].geometry);
    //                 console.log(decodedCoordinates)
    //
    //                 // Decode polyline from the OSRM response
    //                 // const coords = polyline.decode(data.routes[0].geometry);  // Decode the polyline
    //                 // const line = L.polyline(coords, { color: 'blue' }).addTo(map);  // Draw the route on the map
    //                 //
    //                 // // Optionally, add markers for the start and end points
    //                 // L.marker([latA, lonA]).addTo(map).bindPopup('Start: Algiers');
    //                 // L.marker([latB, lonB]).addTo(map).bindPopup('End: Oran');
    //                 //
    //                 // // Adjust the map view to fit the route
    //                 // map.fitBounds(line.getBounds());
    //             });
    //     }
    // },[memorizeIndex])


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    // const [positionsDashed, setPositionsDashed] = useState([])
    // useEffect(() => {
    //     if (positions[index] && positions[index].attributes.prediction)
    //         setPositionsDashed(prevState => [...prevState, positions[index]])
    // }, [index, positions]);

    return (
        <div className={classes.root}>
            <MainToolbar />
            <Map>
                {/*<ReplayPathMap positions={positions} subPositions={subPositions} positionsDashed={positionsDashed} />*/}
                <ReplayPathMap positions={positions} subPositions={subPositions} />
                {index < positions.length &&
                    <>
                        <StartsStops positions={positions} stops={stopsPositions} />
                        {   index !== 0 && <PositionsMap positions={[positions[index]]}/>}
                    </>
                }
            </Map>

            <Container maxWidth="sm" className={classes.controlPanel}>
                {!!positions.length &&
                    <Paper className={classes.controlContent}>
                        <Slider
                            max={positions.length - 1}
                            step={null}
                            marks={positions.map((_, index) => ({ value: index }))}
                            value={index}
                            onChange={(_, index) => setIndex(index)}
                            valueLabelDisplay="auto"
                            valueLabelFormat={i => i < positions.length ? formatPosition(positions[i], 'fixTime') : ''}
                            ValueLabelComponent={TimeLabel}
                        />
                        <div className={classes.controls}>
                        {`${index + 1}/${positions.length}`}
                        <IconButton onClick={goToPreviousPosition} disabled={playing || index <= 0}>
                            <FastRewindIcon />
                        </IconButton>
                        <IconButton onClick={togglePlay}>
                            {playing ? <PauseIcon /> : <PlayArrowIcon />}
                        </IconButton>
                        <IconButton onClick={goToNextPosition} disabled={playing || index >= positions.length - 1}>
                            <FastForwardIcon />
                        </IconButton>
                            <IconButton onClick={handleToggleSpeed}>
                                <FastForwardIcon />
                                <Typography variant="body2" style={{ marginLeft: 4, fontStyle: 'italic', color: 'gray' }}>
                                {t(speedType)}
                            </Typography>
                            </IconButton>
                            <Typography variant="body2" className={classes.timeDisplay}>
                            {formatDate(positions[index].fixTime)}
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                {formatSpeed(positions[index].speed, "kmhFix0")}
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                {/*{formatDistance(positions[index].attributes.distance, "km")}*/}
                                {formatDistance(distanceAccu, "km")}
                            </Typography>
                        </div>
                    </Paper>
                }
                <div>
                    <Accordion className={classes.accordion} expanded={expanded} onChange={() => setExpanded(!expanded)}>
                        <AccordionSummary className={classes.accordionSummary} expandIcon={<ExpandMoreIcon />}>
                            <Typography align='center'>
                                {t('reportConfigure')}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.configForm}>
                            <ReportFilter handleSubmit={handleSubmit} showOnly handleStopsPositions={handleStopsPositions} loading={loading}/>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </Container>

            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                    {t('replayNoData')}
                </Alert>
            </Snackbar>
        </div>
    );
}

export default ReplayPage;
