import { createSlice } from '@reduxjs/toolkit'

const { reducer, actions } = createSlice({
    name : 'notifications',
    initialState : {
        notifications: {},
        selectedId: null
    },
    reducers: {
        refreshNotifications(state, action) {
            state.notifications = {};
            action.payload.forEach(notification => state.notifications[notification['id']] = notification);
        },
        updateNotifications(state, action) {
            action.payload.forEach(notification => state.notifications[notification['id']] = notification);
        },
        selectNotifications(state, action) {
            state.selectedId = action.payload.id;
        },
        removeNotifications(state, action) {
            delete state.notifications[action.payload];
        },
        clearNotifications(state) {
            state.notifications = {};  // Clear all notifications
        },
    }
})

export { actions as notificationsActions };
export { reducer as notificationsReducer };